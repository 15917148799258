<template>
  <div class="container">
    <div class="legal-container">
      <h2 class="heading-secondary">Terms and conditions</h2>
      <h3 class="heading-tertiary">
        1. Acceptance of the Terms and Conditions
      </h3>
      <p class="legal">
        These terms and conditions of use for the INVRS platform (together with
        the website located at
        <a href="https://www.invrs.com/">https://www.invrs.com/</a> and all
        other websites, mobile applications and services), constitute a legal
        agreement and are entered into by and between you being the visitor,
        user, or Creator (hereinafter referred to as "you", "your", "user", or
        "Creator") and Verdant Analysis Inc. the owner and operator of the INVRS
        platform (hereinafter referred to as either "Verdant Analysis," "INVRS,
        " "Company," "we," "us," "our"). The following terms and conditions,
        together with any documents and/or additional terms they expressly
        incorporate by reference (collectively, these "Terms and Conditions"),
        govern your access to and use, including any content, functionality, and
        services offered on or through the INVRS website
        <a href="https://www.invrs.com/">https://www.invrs.com/</a>, mobile
        applications, social media accounts and all other online and other
        platforms (collectively, the "Platform").
      </p>
      <h3 class="heading-tertiary">
        BY VISITING OR USING THE PLATFORM OR BY CLICKING TO ACCEPT THE TERMS AND
        CONDITIONS, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS
        AND CONDITIONS AND OUR PRIVACY POLICY, FOUND AT
        <a href="https://www.invrs.com/privacy">https://www.invrs.com/privacy</a
        >, INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS
        AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE
        WEBSITE.
      </h3>
      <p class="legal">
        By using the Platform, you represent and warrant that you are the legal
        age of majority under applicable law to form a binding contract with
        Verdant Analysis and meet all of the foregoing eligibility requirements.
        If you do not meet all of these requirements, you must not access or use
        the Platform.
      </p>
      <h3 class="heading-tertiary">
        2. Modifications to the Terms and Conditions and to the INVRS Platform
      </h3>
      <p class="legal">
        We reserve the right in our sole discretion to revise and update these
        Terms and Conditions from time to time. Any and all such modifications
        are effective immediately upon posting and apply to all access to and
        continued use of the Platform. You agree to periodically review the
        Terms and Conditions in order to be aware of any such modifications and
        your continued use shall be your acceptance of these.
      </p>
      <p class="legal">
        We reserve the right in our sole discretion to revise and update these
        Terms and Conditions from time to time. Any and all such modifications
        are effective immediately upon posting and apply to all access to and
        continued use of the Platform. You agree to periodically review the
        Terms and Conditions in order to be aware of any such modifications and
        your continued use shall be your acceptance of these.
      </p>

      <h3 class="heading-tertiary">
        3. The INVRS Platform; Disclaimer Concerning Content, Recommendations
        and Advice; Not an Investment or Financial Advisor
      </h3>
      <p class="legal">
        The INVRS Platform is a space that empowers DIY investors who come from
        all walks of life and backgrounds. It's a Platform for like-minded
        individuals who want to work together and learn from one another. The
        INVRS Platform relies heavily on content created by users or User
        Generated Content ("UGC" as defined below), and as such we do not hold
        ourselves out to be financial experts or advisors. All content on the
        Platform is either created by us, third-parties, or users and Creators
        for information purposes only. Furthermore, we are not and do not hold
        ourselves out to be an investment or financial advisor, and we rely on
        the exemption from financial advisor registration under Section 34 of
        the Securities Act (Ontario) and its equivalents in other Canadian
        jurisdictions. We also rely on the "Publisher's Exclusion" from the
        definition of investment advisor under Section 202(a)(11)(D) of the
        Investment Advisors Act of 1940 and corresponding state securities laws.
      </p>
      <p class="legal">
        The Platform does not provide any tax, legal, insurance or investment
        advice, and nothing on the Platform should be construed as an offer to
        sell, a solicitation of an offer to buy, or a recommendation for any
        type of security by us, or any third-party. While we cannot guarantee
        our users will not make such recommendations, we do not encourage such
        behaviour. You alone are solely responsible for determining whether any
        investment, security or strategy, or any other product or service, is
        appropriate for you based on your investment objectives and personal and
        financial situation and for evaluating the merits and risks associated
        with the use of the information on the Platform before making any
        decisions based on such information or other content.
      </p>
      <p class="legal">
        Any specific financial, tax, or legal questions should be discussed with
        a professional financial advisor, accountant, or lawyer.
      </p>
      <p class="legal">
        Financial Markets, whether regulated, unregulated, or of a fictional
        nature are inherently risky and there is no guarantee that patterns,
        algorithms, or past performances are indicative of current or future
        results. As a user of the Platform, we highly encourage that you should
        not assume the future performance of any specific investment or
        investment strategy will be profitable or equal to corresponding past
        performance levels. Inherent in any investment is the potential for
        loss.
      </p>
      <p class="legal">
        Verdant Analysis and INVRS are not fiduciaries by virtue of any person's
        use of or access to the Platform.
      </p>
      <h3 class="heading-tertiary">4. Disclaimer Concerning Securities</h3>
      <p class="legal">
        As a user or even as a visitor of the Platform we want to ensure you
        understand that no content published on the Platform, whether by us, by
        other users and Creators as UGC, or by third-parties, constitutes a
        recommendation that any particular security, cryptocurrency, portfolio
        of securities, transaction or investment strategy is appropriate for any
        particular individual. We also want to ensure you understand that none
        of the Creators, users, information providers, app providers,
        third-parties or their affiliates are advising you personally concerning
        the nature, potential, value or suitability of any particular security,
        portfolio of securities, transaction, investment strategy or other
        matter. To the extent that any of the content published on the Platform
        may be deemed to be investment advice or recommendations in connection
        with a particular security, such information is impersonal and not
        tailored to the investment needs of any specific individual. You
        understand that an investment in any security is subject to a number of
        risks, and that discussions of any security published on the Platform
        will not contain a list or description of relevant risk factors.
      </p>
      <p class="legal">
        You understand that UGC and other content on the Platform may be posted
        and submitted by individuals employed by Verdant Analysis, and that such
        views or opinions expressed may not reflect those of Verdant Analysis as
        a whole. We require all employees to disclose every stock in which they,
        their immediate family, or any entity under their control, have a
        personal interest, if such stock is mentioned in a post, or content
        which they create. However, you understand and agree that non-employees,
        including other users, Creators or other content creators, may post
        about securities in which they or their organizations have a position,
        that they may trade for their own account, and that they may or may not
        be subject to a disclosure policy.
      </p>
      <p class="legal">
        You understand that performance data is supplied by sources believed to
        be reliable, that the calculations on our Platform are made using such
        data, and that such calculations are not guaranteed by these sources,
        the information providers, or any other person or entity, and may not be
        complete. You are solely responsible for any reliance or decisions you
        make as a result of the performance data supplied by our sources.
      </p>
      <p class="legal">
        As of this version of our Terms and Conditions, our performance data is
        supplied by Xignite and QuoteMedia. You can learn more about our
        suppliers by visiting their respective websites below:
      </p>
      <ul>
        <li class="legal">
          <a href="https://www.xignite.com/">Xignite</a>
        </li>
        <li class="legal">
          <a href="https://www.quotemedia.com/">QuoteMedia</a>
        </li>
      </ul>
      <h3 class="heading-tertiary">5. No Reliance</h3>
      <p class="legal">
        Although we make reasonable efforts to update the information on the
        Platform, we make no representations, warranties, or guarantees, whether
        express or implied, that the content is accurate, complete, or up to
        date. Your use of the Platform is at your own risk and neither Verdant
        Analysis nor its parent, subsidiaries, affiliates, and their respective
        directors, officers, employees, agents, service providers, advertisers,
        contractors, licensors, licensees, suppliers, or successors have any
        responsibility or liability whatsoever for your use of the Platform.
      </p>
      <p class="legal">
        This Platform includes UGC created by other users and content provided
        by third-parties, including third-party licensors and advertisers. All
        statements and/or opinions expressed in any such UGC, third-party
        content, advertisement, other than the content provided by Verdant
        Analysis, are solely the opinions and the responsibility of the person
        or entity providing those materials. Such materials do not necessarily
        reflect the opinion of Verdant Analysis. Neither Verdant Analysis nor
        its parent, subsidiaries, affiliates, and their respective directors,
        officers, employees, agents, service providers, advertisers,
        contractors, licensors, licensees, suppliers, or successors have any
        responsibility or liability whatsoever to you, or any third-party, for
        the content or accuracy of any UGC or third-party materials.
      </p>
      <h3 class="heading-tertiary">6. Your Account and Use of the Platform</h3>
      <p class="legal">
        To access most of the features on the Platform, including the ability to
        create UGC, have access to the Creator Dashboard, social feeds, company
        pages, comparison tools, and participate in community collaboration
        efforts through public and private groups, you will need to create an
        account with the Platform.
      </p>
      <p class="legal">
        When you register for an account or update the information in your
        existing account, you agree to provide us with your accurate age and
        information that you will keep up-to-date at all times. Your failure to
        provide us with accurate information is a violation of these Terms and
        Conditions, which will result in your account's immediate suspension or
        termination.
      </p>
      <p class="legal">
        Unless expressly permitted in writing by Verdant Analysis, you may not
        sell, rent, lease, share, or provide access to your account to anyone
        else, including without limitation, charging anyone for access to
        administrative rights on your account. Verdant Analysis reserves all
        available legal rights and remedies to prevent unauthorized use of the
        Platform, including but not limited to, technological barriers, IP
        mapping, and, in serious cases, directly contacting your Internet
        Service Providers (ISP) regarding such unauthorized use.
      </p>
      <p class="legal">
        The security of your personal information is very important to us. We
        use physical, electronic, and administrative measures designed to secure
        your personal information from accidental loss and from unauthorized
        access, use, alteration, and disclosure.
      </p>
      <p class="legal">
        The safety and security of your information also depends on you. Users
        are responsible for obtaining their own access to the Platform. Users
        are required to ensure that all persons who access the Platform through
        a user's internet connection are aware of these Terms and Conditions and
        comply with them. The Platform, including content or areas of the
        Platform, may require user registration. It is a condition of your use
        of the Platform that all the information you provide on the Platform is
        correct, current, and complete.
      </p>
      <p class="legal">
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Platform. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Platform.
      </p>
      <p class="legal">
        Any username, password, or any other piece of information chosen by you,
        or provided to you as part of our security procedures, must be treated
        as confidential, and you must not disclose it to any other person or
        entity. You must exercise caution when accessing your account from a
        public or shared computer so that others are not able to view or record
        your password or other personal information. You understand and agree
        that should you be provided an account, your account is personal to you,
        and you agree not to provide any other person with access to this
        Platform or portions of it using your username, password, or other
        security information. You agree to notify us immediately of any
        unauthorized access to or use of your username or password or any other
        breach of security. You also agree to ensure that you logout from your
        account at the end of each session. You are responsible for any password
        misuse or any unauthorized access.
      </p>
      <p class="legal">
        We reserve the right at any time and from time to time, to disable or
        terminate your account, any username, password, or other identifier,
        whether chosen by you or provided by us, in our sole discretion for any
        or no reason, including any violation of any provision of these Terms
        and Conditions.
      </p>
      <h3 class="heading-tertiary">
        7. Intellectual Property Rights and Ownership
      </h3>
      <p class="legal">
        You understand and agree that the Platform and its entire contents,
        features, and functionality, including, but not limited to, all
        information, software, code, data text, displays, graphics, photographs,
        images, video, audio, music, broadcast, design, presentation, website
        layout, selection, advertisements, and UGC, are owned by Verdant
        Analysis, its third-party licensors and providers, other users or
        Creators, and are protected in all forms by intellectual property laws
        including without limitation, copyright, trademark, patent, trade
        secret, and any other proprietary rights.
      </p>
      <p class="legal">
        You may access and use UGC from the Platform, solely for your personal,
        non-commercial use, unless you are the owner of such UGC or have
        received express written consent from the Creator of the UGC to access
        and use the respective UGC for commercial purposes.
      </p>
      <p class="legal">
        The Verdant Analysis name, the INVRS name, the INVRS brand, the INVRS
        logo, and all related names, logos, product and service names, designs,
        images, and slogans are trademarks of the Verdant Analysis or its
        affiliates or licensors. You must not use such marks without the prior
        written permission of Verdant Analysis. Other names, logos, product and
        service names, designs, images, and slogans mentioned, or which appear
        on the Platform are the trademarks of their respective owners. Use of
        any such property, except as expressly authorized, shall constitute an
        infringement or violation of the rights of the property owner and may be
        a violation of federal or other laws and could subject the infringer to
        legal action.
      </p>
      <h3 class="heading-tertiary">
        8. Conditions of Use and User Generated Content Standards
      </h3>
      <p class="legal">
        As a condition of your access and use, you agree that you may use the
        Platform only for lawful purposes and in accordance with these Terms and
        Conditions.
      </p>
      <p class="legal">
        The following content standards apply to any and all content, material,
        information, and UGC a user or Creator submits, posts, publishes,
        displays, or transmits (collectively, "submit") to the website, to other
        users, Creators, or other persons (collectively, "UGC" as referenced
        above and throughout) and any and all Interactive Functions. Any and all
        UGC must comply with all applicable federal, provincial, local, and
        international laws, and regulations.
      </p>
      <p class="legal">
        Without limiting the foregoing, you warrant and agree that your use of
        the Platform and any UGC shall not:
      </p>
      <p class="legal">
        (a) In any manner violate any applicable federal, provincial, local, or
        international law or regulation including, without limitation, any laws
        regarding the export of data or software, patent, trademark, trade
        secret, copyright, or other intellectual property, legal rights
        (including the rights of publicity and privacy of others) or contain any
        material that could give rise to any civil or criminal liability under
        applicable laws or regulations or that otherwise may be in conflict with
        these Terms and Conditions and our
        <a href="privacy.html">Privacy Policy</a>.
      </p>
      <p class="legal">
        (b) In any manner violate the terms of use of any third-party website
        that is linked to the Platform, including but not limited to, any
        third-party social media website.
      </p>
      <p class="legal">
        (c) Include or contain any material that is exploitive, obscene,
        harmful, threatening, abusive, harassing, hateful, defamatory, sexually
        explicit or pornographic, violent, inflammatory, or discriminatory based
        on race, sex, religion, nationality, disability, sexual orientation, or
        age or other such legally prohibited ground or be otherwise
        objectionable, such determination to be made in Verdant Analysis's sole
        discretion.
      </p>
      <p class="legal">
        (d) Involve stalking, attempting to exploit or harm any individual
        (including minors) in any way by exposing them to inappropriate content
        or otherwise or ask for personal information as prohibited under
        applicable laws, regulations, or code.
      </p>
      <p class="legal">
        (e) Involve, provide, or contribute any false, inaccurate, or misleading
        information, including false securities and cryptocurrencies.
      </p>
      <p class="legal">
        (f) Impersonate or attempt to impersonate Verdant Analysis, INVRS, a
        Company employee, another user or Creator, or any other person or entity
        (including, without limitation, by using email addresses, or screen
        names associated with any of the foregoing).
      </p>
      <p class="legal">
        (g) Transmit, or procure the sending of, any advertisements or
        promotions without our prior written consent, sales, or encourage any
        other commercial activities, including, without limitation, any "spam",
        "junk mail", "chain letter", contests, sweepstakes and other sales
        promotions, barter, or advertising or any other similar solicitation.
      </p>
      <p class="legal">
        (h) Encourage any other conduct that restricts or inhibits anyone's use
        or enjoyment of the Platform, or which, as determined by us, may harm
        Verdant Analysis or users and Creators of the Platform or expose them to
        liability.
      </p>
      <p class="legal">
        (i) Cause annoyance, inconvenience, or needless anxiety or be likely to
        upset, embarrass, or alarm any other person.
      </p>
      <p class="legal">
        (j) Promote any illegal activity, or advocate, promote, or assist any
        unlawful act, including insider dealing, insider trading, or divulge
        insider information.
      </p>
      <p class="legal">
        (k) Give the impression that they originate from or are endorsed by us
        or any other person or entity, if this is not the case.
      </p>
      <h3 class="heading-tertiary">
        9. User Generated Content; Grantng License
      </h3>
      <p class="legal">
        The INVRS Platform is a collaborative community that promotes and
        encourages its users and Creators to interact with one another and
        submit UGC through the Platform.
      </p>
      <p class="legal">
        If you choose to submit, transmit, display, perform, post, or store UGC
        using the Platform, you grant Verdant Analysis and our licensees, to the
        furthest extent and for the maximum duration permitted by applicable law
        (including in perpetuity if permitted under applicable law), an
        unrestricted, worldwide, irrevocable, fully sub-licensable,
        nonexclusive, and royalty-free right to: (a) use, reproduce, modify,
        adapt, publish, translate, create derivative works from, distribute,
        perform, and display such UGC (including without limitation for
        promoting and redistributing part or all of the Platform (and derivative
        works thereof) in any form, format, media, or media channels now known
        or later developed or discovered; and (b) use the name, identity,
        likeness, and voice (or other biographical information) that you submit
        in connection with such UGC.
      </p>
      <p class="legal">
        By submitting the UGC, you declare and warrant that you own or have the
        necessary rights to submit the UGC and have the right to grant the
        licence hereof to us and our licensees, successors, and assigns to the
        UGC and comply with these Terms and Conditions. You represent and
        warrant that all UGC comply with applicable laws and regulations and the
        Conditions of Use and User Generated Content Standards set out in these
        Terms and Conditions.
      </p>
      <p class="legal">
        You are solely responsible for your UGC and the consequences of posting
        or publishing it. You represent and warrant that: (1) you are the
        creator or own or control all right in and to the UGC or otherwise have
        sufficient rights and authority to grant the rights granted herein; (2)
        your UGC does not and will not: (a) infringe, violate, or misappropriate
        any third-party right, including any copyright, trademark, patent, trade
        secret, moral right, privacy right, right of publicity, or any other
        intellectual property or proprietary right, or (b) defame any other
        person; (3) your UGC does not contain any viruses, adware, spyware,
        worms, or other harmful or malicious code; and (4) unless you have
        received prior written authorization, your UGC specifically does not
        contain any insider information or contain pre-disposed knowledge of
        confidential financial information or data, or any other confidential
        information of Verdant Analysis, INVRS, other users and Creators, any
        financial investing or advisory firm, or of any other third-parties. We
        reserve all rights and remedies against any users or Creators who breach
        these representations and warranties.
      </p>
      <p class="legal">
        You understand and agree that you, not Verdant Analysis, nor our parent,
        subsidiaries, affiliates nor their respective directors, officers,
        employees, agents, service providers, advertisers, contractors,
        licensors, suppliers, or successors, are fully responsible for any UGC
        you submit or contribute, and you are fully responsible and legally
        liable, including to any third-party, for such content and its accuracy.
        We are not responsible or legally liable to any third-party for the
        content or accuracy of any UGC submitted by you or any other user of the
        Platform.
      </p>
      <h3 class="heading-tertiary">10. Creator Services</h3>
      <p class="legal">
        As part of your experience on the INVRS Platform, we want to give users
        ("Creator(s)") the ability to post UGC and other content, including
        goods and services for monetization purposes ("Creator Services"), which
        other users can purchase ("Purchasing User(s)"). Any Creator who wishes
        to post or provide Creator Services can do so on their Creator dashboard
        ("Dashboard").
      </p>
      <p class="legal">
        (a) Dashboard and Monetization: Creators will have the ability to post
        Creator Services and UGC for sale on their Dashboard for Purchasing
        Users to view and purchase at the prices listed. Creators have the
        freedom to set their own prices for Creator Services, however, Creators
        are expected to ensure listed prices are honoured without deviation.
      </p>
      <p class="legal">
        (b) Proceeds and Fees: Payment transactions will be received and
        completed by a third-party payment provider approved by us. Creators
        will be able to keep ninety per cent (90%) of all proceeds from Creator
        Services (exclusive of any transaction fees and applicable taxes), with
        INVRS receiving the remaining ten per cent (10%) as part of our service
        fee. Our fee includes the costs of providing, maintaining, and operating
        the INVRS Platform, and is deducted from user payments.
      </p>
      <p class="legal">
        (c) Legal Responsibility of Creators: Any payment transaction for
        Creator Services shall create a transactional contract between the
        Creator and Purchasing User exclusively. Verdant Analysis is not a party
        to any transactional contract between the Creator and Purchasing User.
        Creators and Purchasing Users agree to indemnify and hold Verdant
        Analysis and its affiliates, harmless from any and all losses, costs,
        obligations, liabilities, awards, judgements, fines, penalties, damages,
        or other charges (collectively, "Losses") with respect to any claim,
        action, proceeding, demand, lawsuit, cause of action, or investigation
        of any nature whether at law or equity (collectively, "Claims"), whether
        direct or third party Claims, arising from any payment transactions
        between Creators and Purchasing Users.
      </p>
      <h3 class="heading-tertiary">11. Contests and Promotions</h3>
      <p class="legal">
        As part of our effort to promote a community that creates innovative
        research we provide users with the opportunity to partake in cash-prize
        contests. Our Contests and Promotions are subject to separate terms and
        conditions, which you are required to read before your participation or
        submission of your entry. For more information about Contests and
        Promotions provided by Verdant Analysis and INVRS, including contest
        terms and conditions, please visit the
        <a href="contest.html">Contest</a> page.
      </p>
      <p class="legal">
        Users and Creators may also host their own contests and promotions on
        the INVRS Platform. Such users must ensure their contests or promotions
        are in strict compliance with the applicable requirements of the
        Canadian Competition Act, Criminal Code, consumer protection
        legislation, gaming and lottery legislation, and all other applicable
        law. You must ensure that your contest rules are clearly communicated to
        other users and must disclaim any association or affiliation with
        Verdant Analysis Inc., INVRS, and our affiliates. All contest
        communications must include the following: “This Contest is in no way
        sponsored, endorsed, administered by, or affiliated with, Verdant
        Analysis Inc. or its related entities.” Verdant Analysis Inc. is not
        responsible for any contests or promotions held or hosted by users. If
        you have any questions about the legal compliance of your contest or
        promotion, we encourage you to consult with a lawyer.
      </p>
      <h3 class="heading-tertiary">12. Copyright Policy</h3>
      <p class="legal">
        As a provider of a platform that encourages a community of mutual trust
        and respect, we take the proprietary rights of other's intellectual
        property very seriously, and we encourage our users and Creators to do
        the same. As such, we may in appropriate circumstances and at its
        discretion, terminate the account or access of users or Creators who
        infringe the intellectual property rights of others.
      </p>
      <p class="legal">
        For more information on copyright protections and what might constitute
        a copyright infringement, please visit the
        <a
          href="https://www.ic.gc.ca/eic/site/cipointernet-internetopic.nsf/eng/h_wr02281.html"
          >Canadian Intellectual Property Office (CIPO) website</a
        >
        for their guide to copyrights.
      </p>
      <p class="legal">
        If you believe your work has been copied in a way that constitutes
        copyright infringement, please provide our Copyright Agent the following
        information:
      </p>
      <ul>
        <li class="legal">
          an electronic or physical signature of the person authorized to act on
          behalf of the owner of the copyright interest;
        </li>
        <li class="legal">
          a description of the copyrighted work that you claim has been
          infringed, including the URL (web page address) of the location where
          the copyrighted work exists or a copy of the copyrighted work;
        </li>
        <li class="legal">
          a description of where the material that you claim is infringing is
          located on the Site, including the URL;
        </li>
        <li class="legal">
          your address, telephone number, and email address;
        </li>
        <li class="legal">
          a statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
          and
        </li>
        <li class="legal">
          a statement by you, made under penalty of perjury, that the above
          information in your Notice is accurate and that you are the copyright
          owner or authorized to act on the copyright owner's behalf.
        </li>
      </ul>
      <p class="legal">
        Our Copyright Agent for Notice of claims of copyright infringement can
        be reached by email at info@invrs.com.
      </p>
      <h3 class="heading-tertiary">
        13. Site Monitoring and Enforcement; Suspension and Termination
      </h3>
      <p class="legal">
        13. Site Monitoring and Enforcement; Suspension and Termination We
        maintain the right, without provision of notice to:
      </p>
      <ul>
        <li class="legal">
          Remove or refuse to post on the Platform any UGC for any or no reason
          in our sole discretion.
        </li>
        <li class="legal">
          At all times, take such actions with respect to any UGC deemed
          necessary or appropriate in our sole discretion, including, without
          limitation, for violating the Platform and Conditions of Use and User
          Generated Content Standards or Terms and Conditions.
        </li>
        <li class="legal">
          Take appropriate legal action, including, without limitation, referral
          to law enforcement or regulatory authority, or notifying the harmed
          party of any illegal or unauthorized use of the Platform. Without
          limiting the foregoing, we have the right to fully cooperate with any
          law enforcement authorities or court order requesting or directing us
          to disclose the identity or other information of anyone posting any
          materials on or through the Platform.
        </li>
        <li class="legal">
          Terminate or suspend your access to all or part of the Platform for
          any or no reason, including, without limitation, any violation of
          these Terms and Conditions.
        </li>
      </ul>
      <p class="legal">
        YOU WAIVE AND HOLD HARMLESS THE VERDANT ANALYSIS AND ITS PARENT,
        SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
        EMPLOYEES, AGENTS, SERVICE PROVIDERS, ADVERTISERS, CONTRACTORS,
        LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS
        RESULTING FROM ANY ACTION TAKEN BY VERDANT ANALYSIS AND ANY OF THE
        FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER VERDANT
        ANALYSIS OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES, OR BY
        AUTHORITIES FROM THE ONTARIO SECURITIES COMMISSION OR THE SECURITIES AND
        EXCHANGE COMMISSION.
      </p>
      <p class="legal">
        We have no obligation, nor any responsibility to any party to monitor
        the Platform or its use, and do not and cannot undertake to review
        material that you or other users and Creators submit to the Platform. We
        cannot ensure prompt removal of objectionable material after it has been
        posted and we have no liability for any action or inaction regarding
        transmissions, communications, or content provided by any user, Creator,
        or third-party, subject to applicable laws.
      </p>
      <h3 class="heading-tertiary">14. Privacy</h3>
      <p class="legal">
        By submitting your personal information and using our Platform, you
        consent to the collection, use, reproduction, hosting, transmission, and
        disclosure of any such user content submissions in compliance with our
        <a href="privacy.html">Privacy Policy</a>
        as we deem necessary for use of the Platform.
      </p>
      <h3 class="heading-tertiary">15. Third-Party Websites</h3>
      <p class="legal">
        For your convenience, the Platform contains links and pointers to
        third-party sites and content created by third-parties, which may be
        published and uploaded on this Platform. Such links and pointers may be
        posted by us or by other users, Creators, advertisers, or third-parties.
        We make no representations about any other websites that may be accessed
        from the Platform. If you choose to access any such sites, you do so at
        your own risk. We have no control over the contents of any such
        third-party sites and accept no responsibility for such sites or for any
        loss or damage that may arise from your use of them. You are subject to
        any terms and conditions of such third-party sites.
      </p>
      <p class="legal">
        You may link to any content on the site, including other users and
        Creator's UGC, provided you do so in a way that is fair and legal and
        does not damage our reputation or take advantage of it. You must not
        establish a link in such a way as to suggest any form of association,
        approval, or endorsement on our part where none exists. We reserve the
        right to withdraw linking permission without notice. The website in
        which you are linking must comply in all respects with the Conditions of
        Use and User Generated Content Standards. You agree to cooperate with us
        in causing any unauthorized framing or linking to immediately stop.
      </p>
      <h3 class="heading-tertiary">16. Third-Party Content</h3>
      <p class="legal">
        In addition to the UGC, we may provide other third-party content,
        including advertising, on the Platform. We do this so that we can offer
        our Platform to users and Creators free of charge.
      </p>
      <p class="legal">
        We do not control or endorse any third-party content and we make no
        representation or warranties of any kind regarding the third-party
        content, including without limitation regarding its accuracy or
        completeness. Please be aware that we do not create third-party content,
        update, or monitor it, and that we are not responsible for any
        third-party content on the Platform.
      </p>
      <p class="legal">
        You are responsible for deciding if you want to access or use
        third-party websites or applications that link from the Platform (the
        "Reference Sites"). We do not control or endorse any such Reference
        Sites or the information, materials, products, or services contained on
        or accessible through Reference Sites, and make no representations or
        warranties of any kind regarding the Reference Sites. In addition, your
        correspondence or business dealings with, or participation in promotions
        of advertisers found on or through the Platform are solely between you
        and such advertiser. Access and use of Reference Sites, including the
        information, materials, products, and services on or available through
        Reference Sites is solely at your own risk.
      </p>
      <h3 class="heading-tertiary">17. Geographic Restrictions</h3>
      <p class="legal">
        At this time, we currently provide the Platform for use only by persons
        and individuals located in Canada. This Platform is not intended for use
        in any jurisdiction where its use is not permitted. If you access the
        Platform from outside Canada, you do so at your own risk and you are
        responsible for compliance with local laws of your jurisdiction.
      </p>
      <h3 class="heading-tertiary">18. Disclaimer of Warranties</h3>
      <p class="legal">
        YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE PLATFORM, ITS CONTENT, AND
        ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE PLATFORM IS AT YOUR
        OWN RISK. THE PLATFORM, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR
        ATTAINED THROUGH THE PLATFORM ARE PROVIDED ON AN "AS IS" AND "AS
        AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND,
        EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
        NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
        CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </p>
      <p class="legal">
        NEITHER VERDANT ANALYSIS NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR
        THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
        PROVIDERS, ADVERTISERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR
        SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH
        RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY,
        ACCURACY, CURRENCY, OR AVAILABILITY OF THE PLATFORM OR ITS CONTENTS.
        WITHOUT LIMITING THE FOREGOING, NEITHER VERDANT ANALYSIS NOR ITS PARENT,
        SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS,
        EMPLOYEES, AGENTS, SERVICE PROVIDERS, ADVERTISERS, CONTRACTORS,
        LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS REPRESENT OR WARRANT THAT
        THE PLATFORM, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED
        THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
        UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE
        SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS.
      </p>
      <p class="legal">
        WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
        FOR DOWNLOADING FROM THE INTERNET OR THE PLATFORM WILL BE FREE OF
        VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY
        RESPONSIBLE FOR YOUR USE OF THE PLATFORM AND YOUR COMPUTER, INTERNET,
        AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE
        LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK,
        DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING,
        MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR
        OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, MOBILE DEVICES, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
        MATERIAL DUE TO YOUR USE OF THE PLATFORM OR ANY SERVICES OR ITEMS FOUND
        OR ATTAINED THROUGH THE PLATFORM OR TO YOUR DOWNLOADING OF ANY MATERIAL
        POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
      </p>
      <h3 class="heading-tertiary">19. Limitation of Liability</h3>
      <p class="legal">
        EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO
        CIRCUMSTANCE WILL VERDANT ANALYSIS NOR ITS PARENT, SUBSIDIARIES,
        AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
        SERVICE PROVIDERS, ADVERTISERS, CONTRACTORS, LICENSORS, LICENSEES,
        SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE,
        NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND,
        UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
        INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT
        LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
        LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
        SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED
        BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY,
        OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO
        KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
        USE, OR RELIANCE ON, THE PLATFORM, ANY LINKED WEBSITES OR SUCH OTHER
        THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING, OR
        INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD
        REASON TO KNOW.
      </p>
      <h3 class="heading-tertiary">20. Indemnification</h3>
      <p class="legal">
        To the maximum extent permitted by applicable law, you agree to defend,
        indemnify, and hold harmless Verdant Analysis, INVRS, its parent,
        subsidiaries, affiliates, and their respective directors, officers,
        employees, agents, service providers, advertisers, contractors,
        licensors, suppliers, successors, and assigns from and against any
        claims, liabilities, damages, judgments, awards, losses, costs,
        expenses, or fees (including reasonable attorneys' fees) arising out of
        or relating to your breach of these Terms and Conditions or your use of
        the Platform, including, but not limited to, your UGC, third-party
        sites, any use of the Platform's content, services, and products other
        than as expressly authorized in these Terms and Conditions.
      </p>
      <h3 class="heading-tertiary">
        21. Governing Law; No Waiver; Dispute Resolution and Arbitration
      </h3>
      <p class="legal">
        These Terms and Conditions and the relationship between you and Verdant
        Analysis will be governed by the laws of the Province of Ontario and the
        applicable federal laws of Canada.
      </p>
      <p class="legal">
        Our failure to exercise or enforce any right or provision of these Terms
        and Conditions will not constitute a waiver of such right or provision.
        If any provision of these Terms and Conditions is found by a court of
        competent jurisdiction to be invalid, the parties to these Terms and
        Conditions (the "Parties") nevertheless agree that the court should
        endeavor to give effect to the Parties' intentions as reflected in the
        provision, and the other provisions of these Terms and Conditions remain
        in full force and effect. You agree that regardless of any statute or
        law to the contrary, any claim or cause of action arising out of or
        related to use of the Platform or these Terms and Conditions must be
        filed within one (1) year after such claim or cause of action arose or
        be forever barred. The section titles in these Terms and Conditions are
        for convenience only and have no legal or contractual effect.
      </p>
      <p class="legal">
        To the fullest extent permitted by applicable law and subject to the
        arbitration provisions below, you irrevocably and unconditionally submit
        to the exclusive jurisdiction of the courts of the Province of Ontario,
        Canada, with respect to any dispute, controversy or claim (a "Dispute")
        arising out of or in connection with these Terms and Conditions or your
        use of the Platform.
      </p>
      <p class="legal">
        The rights and obligations of the Parties set forth in this Section 21,
        Sections 3, 4, 5, 7, 9, 12, 18, 19 and 20, and any other provision in
        these Terms and Conditions which, by its nature, should survive
        termination of these Terms and Conditions, will survive any such
        termination.
      </p>
      <p class="legal">
        WHERE PERMITTED UNDER APPLICABLE LAW, YOU AND VERDANT ANALYSIS MAY BRING
        CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT
        AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
        ACTION. YOU AND VERDANT ANALYSIS AGREE TO WAIVE THE RIGHT TO A TRIAL BY
        JURY WITH RESPECT TO ANY DISPUTES. UNLESS BOTH YOU AND VERDANT ANALYSIS
        AGREE, NO ARBITRATOR OR JUDGE MAY CONSOLIDATE MORE THAN ONE PERSON'S
        CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS
        PROCEEDING.
      </p>
      <p class="legal">
        If you are located in, are based in, have offices in, or do business in
        a jurisdiction in which the following provision is enforceable, the
        following mandatory arbitration provision applies to you. You agree that
        any Dispute shall be submitted to and determined by binding arbitration
        in accordance with the Simplified Arbitration Rules of the ADR Institute
        of Canada, Inc. The number of arbitrators shall be one, and the Parties
        shall mutually agree and appoint an arbitrator within 10 days of the
        delivery of the notice of arbitration, failing which the arbitrator
        shall be appointed by the ADR Institute of Canada, Inc. The place of
        arbitration shall be Toronto, Ontario, Canada. The language of
        arbitration shall be English. By agreeing to this provision, the Parties
        agree that arbitration shall be the exclusive forum for resolving all
        Disputes and the decision of the arbitrator shall be final and binding
        upon the Parties. The decision of the arbitrator shall be executory, and
        judgment thereon may be entered by any court of competent jurisdiction.
        The arbitration proceeding and any decision by the arbitration panel
        shall be confidential, unless disclosure is required by applicable law.
      </p>
      <h3 class="heading-tertiary">22. Severability</h3>
      <p class="legal">
        If any term or provision of these Terms and Conditions is invalid,
        illegal, or unenforceable in any jurisdiction, such invalidity,
        illegality, or unenforceability shall not affect any other term or
        provision of these Terms and Conditions or invalidate or render
        unenforceable such term or provision in any other jurisdiction.
      </p>
      <h3 class="heading-tertiary">23. Entire Agreement</h3>
      <p class="legal">
        The Terms and Conditions and our Privacy Policy constitute the sole and
        entire agreement between you and Verdant Analysis regarding the Platform
        and supersedes all prior and contemporaneous understandings, agreements,
        representations and warranties, both written and oral, regarding such
        subject matter.
      </p>
      <h3 class="heading-tertiary">24. Reporting and Contact</h3>
      <p class="legal">
        This website is operated by Verdant Analysis Inc. of 5700-100 King
        Street West, Toronto, ON M5X 1C7.
      </p>
      <p class="legal">
        Should you become aware of misuse of the website including libelous or
        defamatory conduct, you must report it to Verdant Analysis at
        info@invrs.com.
      </p>
      <p class="legal">
        All other feedback, comments, requests for technical support, and other
        communications relating to the Platform should be directed to
        info@invrs.com.
      </p>
      <p class="legal">December 2021</p>
    </div>
  </div>
</template>

<style scoped>
.legal {
  margin-bottom: 3.2rem;
  line-height: 1.3;
}

.legal-container {
  padding: 14.4rem 12rem;
}

.heading-tertiary {
  margin-bottom: 1.6rem;
  line-height: 1.2;
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .legal-container {
    padding: 14.4rem 6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .legal-container {
    padding: 12.8rem 2.4rem;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .legal-container {
    padding: 12.8rem 1.2rem;
  }
}
</style>
